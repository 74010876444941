export const loadBackgroundImg = require.context(
  '../assets/images/welcome',
  true,
  /.png$/
)
export const loadVirtualBackgroundImg = require.context(
  '../assets/images/virtual',
  true,
  /.png$/
)
