import React, { memo } from 'react'
import { Input, Slider, Row, Tooltip } from 'antd'
import styles from './style.module.less'
import Excellent from '@/assets/images/survey/Excellent.png'
import Bad from '@/assets/images/survey/Bad.png'
import UnLikIcon from '@/assets/images/survey/like.png'
import LikedIcon from '@/assets/images/survey/liked.png'
import { RATINGKEY, INPUTKEY, THUMBTITLE } from '@/constants'

const CMSFieldInput = ({
  onChange = (id, key, val) => {},
  showError,
  disabled,
  liked,
  ...props
}) => {
  return (
    <div className={styles.cmsFieldInput}>
      <label>{props?.name}</label>
      <div
        className={
          !props?.[RATINGKEY] && showError && props?.ratingRequired
            ? styles.ratingBorder
            : {}
        }
      >
        {props?.ratingShow ? (
          props?.ratingType == 'thumb' ? (
            THUMBTITLE.map((items, index) => (
              <Tooltip key={index} title={items}>
                <button
                  className={styles.feedbackThumb}
                  tabindex={0}
                  id={`feedback-${index}`}
                  disabled={disabled}
                  onClick={() => {
                    !disabled && onChange(props?._id, RATINGKEY, index + 1)
                  }}
                >
                  <img
                    alt={items}
                    src={index < props?.[RATINGKEY] ? LikedIcon : UnLikIcon}
                    className={styles.img}
                    // onClick={() => {
                    //   !disabled && onChange(props?._id, RATINGKEY, index + 1)
                    // }}
                  />
                </button>
              </Tooltip>
            ))
          ) : (
            <Row className={styles.sliderView}>
              <Tooltip title={props?.minRateLabel}>
                <img src={Bad} />
              </Tooltip>
              <Slider
                min={0}
                max={props?.maxRating}
                className={styles.slider}
                disabled={disabled}
                value={props?.[RATINGKEY]}
                onChange={(v) => {
                  onChange(props?._id, RATINGKEY, v)
                }}
              />
              <Tooltip title={props?.maxRateLabel}>
                <img src={Excellent} />
              </Tooltip>
            </Row>
          )
        ) : null}
      </div>
      {props?.inputShow ? (
        <Input.TextArea
          placeholder="Feedback"
          size="large"
          value={props?.[INPUTKEY]}
          disabled={disabled}
          onChange={(e) => {
            onChange(props?._id, INPUTKEY, e.target.value)
          }}
          style={{
            borderColor:
              !props?.[INPUTKEY] && showError && props?.inputRequired
                ? 'red'
                : ''
          }}
          autoSize
        />
      ) : null}
    </div>
  )
}

export default memo(CMSFieldInput)
