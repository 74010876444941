import { COMMON } from '../../constants'
import { loadBackgroundImg, loadVirtualBackgroundImg } from '../../utils/image'

const BACKGROUND_IMAGE_TOTAL = 13
const VIRTUAL_BACKGROUND_IMAGE_TOTAL = 25

const getBackgroundImgs = () => {
  let bg_list = []
  for (var i = 1; i <= BACKGROUND_IMAGE_TOTAL; i++) {
    const image = loadBackgroundImg(`./welcome-bg${i}.png`)
    if (image) bg_list.push(image.default)
  }
  return bg_list
}

const getVirtualBackgroundImgs = () => {
  let bg_list = []
  for (var i = 1; i <= VIRTUAL_BACKGROUND_IMAGE_TOTAL; i++) {
    const image = loadVirtualBackgroundImg(`./virtual-bg${i}.png`)
    if (image) bg_list.push(image.default)
  }
  return bg_list
}

const initState = {
  backgroundImgs: getBackgroundImgs(),
  virtualBackgroundImgs: getVirtualBackgroundImgs(),
  selectedBg: localStorage.getItem('background')
    ? localStorage.getItem('background')
    : null,
  alert: null
}

const CommonReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case COMMON.CHOOSE_BG:
      return {
        ...state,
        selectedBg: payload
      }

    case COMMON.TOP_ALERT:
      return {
        ...state,
        alert: Object.assign({}, payload)
      }

    case COMMON.CLEAR_ALERT:
      return {
        ...state,
        alert: null
      }

    default:
      return state
  }
}

export default CommonReducer
