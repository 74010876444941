import React, { useState } from 'react'
import CMSFieldInput from './CMSFieldInput'
import styles from './style.module.less'

const FeedbackForm = ({ formFields, setformFields, formSubmitted }) => {
  const [error, _error] = useState(false)

  const _onChange = (id, key, val) => {
    const newValue = [...formFields]?.map((e) =>
      id === e?._id || id === e?.id ? { ...e, [key]: val } : e
    )
    setformFields([...newValue])
  }

  return (
    <div className={`${styles.FeedbackForm} feedback`}>
      <div data-tour="feedback-form-step">
        {formFields?.map((d) => {
          return (
            <CMSFieldInput
              {...d}
              key={d._id || d.id}
              _id={d._id || d.id}
              onChange={_onChange}
              showError={error}
              disabled={formSubmitted}
            />
          )
        })}
      </div>
    </div>
  )
}

export default FeedbackForm
