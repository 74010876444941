import { combineReducers } from 'redux'
import CommonReducer from './common/reducer'
import AuthReducer from './auth/reducer'
import RoomReducer from './room/reducer'

const reducer = combineReducers({
  common: CommonReducer,
  auth: AuthReducer,
  room: RoomReducer
})

export default reducer
