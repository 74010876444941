import React, { memo, useEffect, useState } from 'react'
import { Collapse } from 'antd'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'
import { ROLES_VAL, STEP_VAL } from '../../constants'
import './style.less'
import FeedbackForm from '../FeedbackForm'

const { Panel } = Collapse

const CollapseIcon = (props) => {
  return (
    <div {...props} className={'CollapseIcon'}>
      {!props.isActive ? (
        <PlusOutlined style={{ fontSize: '14px' }} />
      ) : (
        <CloseOutlined style={{ fontSize: '14px' }} />
      )}
    </div>
  )
}

const TipCollapse = ({
  role,
  roles,
  roleDis,
  interaction,
  questions,
  showbtn,
  isExpanded,
  currentRole,
  prompt,
  promptData,
  sequenceNumber,
  timerStep,
  roomId,
  isRoleSelected,
  submitFeedback,
  startOrPause,
  goNextStep,
  timerStarted,
  timerStartedOnce,
  timerCompleted,
  formFields,
  setformFields,
  formSubmitted
}) => {
  const [competency, setCompetency] = useState([])

  useEffect(() => {
    if (questions) {
      const data = questions
      const competencyname = data.filter(
        (e) => e.questionCategory == 'competency'
      )
      setCompetency(competencyname)
    }
  }, [])

  return (
    <div className={`collapseContainer ${showbtn ? 'withBtn' : 'noBtn'}`}>
      <div data-tour="tips-step">
        <Collapse
          ghost
          accordion
          defaultActiveKey={
            currentRole === roles?.[ROLES_VAL?.listener]?.name ? '1' : undefined
          }
        >
          <Panel
            showArrow={false}
            key="1"
            className="header about"
            header={<button className="headerBtn">About this role</button>}
          >
            {roles?.[ROLES_VAL?.subject]?.name == currentRole ? (
              <div
                className="list"
                dangerouslySetInnerHTML={{
                  __html: roleDis?.role1Description
                }}
              />
            ) : null}

            {roles?.[ROLES_VAL?.listener]?.name == currentRole ? (
              <div>
                <div
                  className="list"
                  dangerouslySetInnerHTML={{
                    __html: role?.aboutTheRolePart1
                  }}
                />
                <div
                  className="list"
                  dangerouslySetInnerHTML={{
                    __html: roleDis?.role2Description || ''
                  }}
                />
                <div
                  className="list"
                  dangerouslySetInnerHTML={{
                    __html: role?.aboutTheRolePart2
                  }}
                />
              </div>
            ) : null}
            {roles?.[ROLES_VAL?.observer]?.name == currentRole ? (
              <div>
                <div
                  className="list"
                  dangerouslySetInnerHTML={{
                    __html: role?.aboutTheRolePart1
                  }}
                />
              </div>
            ) : null}
          </Panel>
        </Collapse>

        {roles?.[ROLES_VAL?.subject]?.name === currentRole &&
          promptData.showInRoom && (
            <div>
              <Collapse ghost expandIcon={CollapseIcon} defaultActiveKey="2">
                <Panel
                  header={
                    <button className="headerBtn">
                      {promptData?.promptButtonTitle || 'Prompt'}
                    </button>
                  }
                  key="2"
                  showArrow={false}
                  className={'header'}
                >
                  <p className="expandContent">{prompt || 'No data'}</p>
                </Panel>
              </Collapse>
            </div>
          )}

        {roles?.[ROLES_VAL?.observer]?.name === currentRole &&
          timerStep < STEP_VAL.reset && (
            <div>
              <Collapse ghost expandIcon={CollapseIcon} defaultActiveKey="8">
                <Panel
                  header={
                    <button className="headerBtn">
                      In-Session Reflections<div>Required</div>
                    </button>
                  }
                  key="8"
                  showArrow={false}
                  className={'header feedback-form'}
                >
                  <FeedbackForm
                    formFields={questions}
                    setformFields={setformFields}
                    formSubmitted={formSubmitted}
                  />
                </Panel>
              </Collapse>
            </div>
          )}

        <div>
          <Collapse ghost expandIcon={CollapseIcon}>
            <Panel
              header={
                <button className="headerBtn">
                  {role?.tipsTitle || 'Helpful Tips'}
                </button>
              }
              key="3"
              showArrow={false}
              className={'header'}
            >
              <div
                className="list"
                dangerouslySetInnerHTML={{ __html: role?.helpfullTips }}
              />
            </Panel>
          </Collapse>
        </div>

        {roles?.[ROLES_VAL?.subject]?.name === currentRole &&
          (promptData?.showReflectionQuestions === 'always' ||
            timerStep === STEP_VAL.debrief) && (
            <div>
              <Collapse ghost expandIcon={CollapseIcon}>
                <Panel
                  header={
                    <button className="headerBtn">
                      {promptData?.reflectionQuestionDisplayTitle ||
                        'Reflection Questions'}
                    </button>
                  }
                  key="4"
                  showArrow={false}
                  className={'header'}
                >
                  <div
                    className="list"
                    dangerouslySetInnerHTML={{
                      __html: promptData?.reflectionQuestion || 'No data'
                    }}
                  />
                </Panel>
              </Collapse>
            </div>
          )}

        {roles?.[ROLES_VAL?.listener]?.name === currentRole && (
          <>
            <div>
              <Collapse ghost expandIcon={CollapseIcon}>
                <Panel
                  header={
                    <button className="headerBtn">
                      {promptData?.supportQuestionDisplayTitle ||
                        'Questions/Focus'}
                    </button>
                  }
                  key="6"
                  showArrow={false}
                  className={'header'}
                >
                  <div
                    className="list"
                    dangerouslySetInnerHTML={{
                      __html: promptData?.supportQuestion || 'No data'
                    }}
                  />
                </Panel>
              </Collapse>
            </div>
            <div>
              <Collapse ghost expandIcon={CollapseIcon}>
                <Panel
                  header={
                    <button className="headerBtn">
                      {interaction?.holdingSpaceDisplayTitle || 'Holding Space'}
                    </button>
                  }
                  key="7"
                  showArrow={false}
                  className={'header'}
                >
                  <div
                    className="list"
                    dangerouslySetInnerHTML={{
                      __html: interaction?.holdingSpace || 'No data'
                    }}
                  />
                </Panel>
              </Collapse>
            </div>
          </>
        )}

        {roles?.[ROLES_VAL?.observer]?.name === currentRole && (
          <div>
            <Collapse ghost expandIcon={CollapseIcon}>
              <Panel
                header={
                  <button className="headerBtn">
                    {interaction?.leadingDebriefDisplayTitle ||
                      'Leading Debriefs'}
                  </button>
                }
                key="10"
                showArrow={false}
                className={'header'}
              >
                <div
                  className="list"
                  dangerouslySetInnerHTML={{
                    __html: interaction?.leadingDebrief || 'No data'
                  }}
                />
              </Panel>
            </Collapse>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(TipCollapse)
