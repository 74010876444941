import axios from 'axios'
import { setUserId, setUserProperties } from 'firebase/analytics'

import { AUTH, COMMON } from '../../constants'
import { publicApi, privateApi } from '../../utils/api'
import { getAnalytics } from '../../utils/initFirebase'

// Get User with Token
export const getCurrentUser = () => {
  return async (dispatch) => {
    try {
      const res = await privateApi.get('/user/current')
      if (res?.token) {
        window.localStorage.setItem('accessToken', res?.token)
      }

      dispatch({
        type: AUTH.SIGNED_IN
      })
      dispatch({
        type: AUTH.USER_INFO,
        payload: res
      })

      const {
        firstName,
        lastName,
        _id: userId,
        companyId,
        email,
        type
      } = res || {}

      const analytics = getAnalytics()
      setUserProperties(analytics, {
        firstName,
        lastName,
        userId,
        companyId,
        email,
        type
      })
      setUserId(analytics, userId)

      return res
    } catch (err) {
      dispatch(logOut())
    }
  }
}

// Login
export const login = (obj) => (dispatch) =>
  publicApi
    .post('/user/signin', obj)
    .then((res) => {
      dispatch({
        type: AUTH.SIGNED_IN
      })
      window.localStorage.setItem('accessToken', res.token)
      return res
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.message }
      })
      throw err
    })

// Google Login
export const googleLogin = (oauthAccessToken) => (dispatch) =>
  publicApi
    .post('/user/google-login', { oauthAccessToken })
    .then((res) => {
      dispatch({
        type: AUTH.SIGNED_IN
      })
      window.localStorage.setItem('accessToken', res.token)
      return res
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.message }
      })
      throw err
    })

//Reigster
export const register = (obj) => {
  return (dispatch) => {
    return publicApi
      .post('/user/signup', obj)
      .then((res) => {
        dispatch({
          type: AUTH.SIGNED_IN
        })
        window.localStorage.setItem('accessToken', res.token)
        return res
      })
      .catch((err) => {
        dispatch({
          type: COMMON.TOP_ALERT,
          payload: { type: 'error', message: err.message }
        })
        throw err
      })
  }
}

// Google Register
export const googleRegister = (code) => (dispatch) =>
  publicApi
    .post('/user/google-register', { code })
    .then((res) => {
      dispatch({
        type: AUTH.SIGNED_IN
      })
      window.localStorage.setItem('accessToken', res.token)
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.message }
      })
      throw err
    })

export const logOut = () => {
  window.localStorage.removeItem('accessToken')
  return (dispatch) => {
    dispatch({
      type: AUTH.SIGNED_OUT
    })
  }
}

// Reset password & change password
export const resetPwdRequest = (obj) => (dispatch) =>
  publicApi
    .post('/user/reset-password', obj)
    .then((res) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: {
          type: 'info',
          message: 'Email sent, Please check your mailbox!'
        }
      })
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.message }
      })
      throw err
    })

export const changePassword = (obj, token) => (dispatch) => {
  return axios
    .post(process.env.REACT_APP_API_URL + '/user/change-password', obj, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    .then((res) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'info', message: res.data.message }
      })
      return res.data.message
    })
    .catch((err) => {
      dispatch({
        type: COMMON.TOP_ALERT,
        payload: { type: 'error', message: err.message }
      })
      throw err
    })
}

export const uploadBackgroundImage = (img) => {
  return (dispatch) => {
    dispatch({
      type: AUTH.BACKGROUND_IMAGE_UPLOAD,
      payload: img
    })
  }
}
export const clientInfo = (client) => {
  return (dispatch) => {
    dispatch({
      type: AUTH.CLIENT,
      payload: client
    })
  }
}

export const verifyEmail = (email = '') => {
  if (!email) return
  return publicApi.get(`/user/validate-account?email=${email}`)
}
