import { initializeApp } from 'firebase/app'
import { getAnalytics as getAnalyticsFirebase } from 'firebase/analytics'
import { getFirestore as getFirestoreFirebase } from 'firebase/firestore'
import { GoogleAuthProvider } from 'firebase/auth'

export const firebaseConfig = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.FIREBASE_APP_ID,
  measurementId: process.env.FIREBASE_MEASURMENT_ID
}

export const app = initializeApp(firebaseConfig)
export const provider = new GoogleAuthProvider()

export function getFirestore() {
  return getFirestoreFirebase(app)
}

export function getAnalytics() {
  return getAnalyticsFirebase(app)
}
