import React from 'react'

const ModalRight = ({ className }) => (
  <svg
    className={className}
    width="178"
    height="100%"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M106.625 0H178V99992H0.884155V105.741C0.884155 47.3417 48.2259 0 106.625 0Z"
      fill="#80D9C6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.536 432.066C104.114 429.621 100.814 428.247 97.3726 428.25C93.9313 428.247 90.6319 429.621 88.2096 432.066C83.1482 437.163 83.1482 445.39 88.2096 450.488C90.6337 452.93 93.9321 454.303 97.3729 454.303C100.814 454.303 104.112 452.93 106.536 450.488C111.597 445.39 111.597 437.164 106.536 432.066ZM97.3727 431.224C100.028 431.224 102.574 432.284 104.445 434.168C108.35 438.103 108.35 444.451 104.445 448.386C102.574 450.27 100.028 451.33 97.3727 451.33C94.7172 451.33 92.1716 450.27 90.3007 448.386C86.3956 444.451 86.3956 438.103 90.3007 434.168C92.1716 432.284 94.7172 431.224 97.3727 431.224Z"
      fill="#ECFFFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M106.536 492.72C104.114 490.276 100.814 488.902 97.3726 488.904C93.9313 488.902 90.6319 490.276 88.2096 492.72C83.1482 497.818 83.1482 506.044 88.2096 511.142C90.6336 513.584 93.9321 514.958 97.3729 514.958C100.814 514.958 104.112 513.584 106.536 511.142C111.597 506.044 111.597 497.818 106.536 492.72ZM97.3727 491.878C100.028 491.878 102.574 492.938 104.445 494.823C108.349 498.758 108.349 505.105 104.445 509.04C102.574 510.925 100.028 511.984 97.3727 511.984C94.7172 511.984 92.1716 510.925 90.3007 509.04C86.3956 505.106 86.3956 498.758 90.3007 494.823C92.1716 492.938 94.7172 491.878 97.3727 491.878Z"
      fill="#ECFFFD"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M74.3873 131.613C74.3873 132.746 75.2927 133.672 76.426 133.698H119.376C120.51 133.672 121.415 132.746 121.415 131.613C121.415 130.479 120.51 129.553 119.376 129.528H76.426C75.2927 129.553 74.3873 130.479 74.3873 131.613ZM76.4258 162.257C75.287 162.239 74.3733 161.311 74.3733 160.172C74.3733 159.033 75.287 158.104 76.4258 158.086H119.376C120.515 158.104 121.429 159.033 121.429 160.172C121.429 161.311 120.515 162.239 119.376 162.257H76.4258ZM74.387 188.731C74.387 189.865 75.2925 190.791 76.4258 190.817H119.376C120.509 190.791 121.415 189.865 121.415 188.731C121.415 187.598 120.509 186.672 119.376 186.646H76.4258C75.2925 186.672 74.387 187.598 74.387 188.731ZM76.4258 219.376C75.287 219.357 74.3733 218.429 74.3733 217.29C74.3733 216.151 75.287 215.223 76.4258 215.205H119.376C120.515 215.223 121.429 216.151 121.429 217.29C121.429 218.429 120.515 219.357 119.376 219.376H76.4258ZM74.3733 245.85C74.3733 246.989 75.287 247.917 76.4258 247.935H119.376C120.515 247.917 121.429 246.989 121.429 245.85C121.429 244.711 120.515 243.782 119.376 243.764H76.4258C75.287 243.782 74.3733 244.711 74.3733 245.85ZM76.4258 276.494C75.287 276.476 74.3733 275.548 74.3733 274.409C74.3733 273.27 75.287 272.341 76.4258 272.323H119.376C120.515 272.341 121.429 273.27 121.429 274.409C121.429 275.548 120.515 276.476 119.376 276.494H76.4258ZM74.387 302.968C74.387 304.102 75.2925 305.028 76.4258 305.053H119.376C120.509 305.028 121.415 304.102 121.415 302.968C121.415 301.834 120.509 300.908 119.376 300.883H76.4258C75.2925 300.908 74.387 301.834 74.387 302.968ZM74.387 331.527C74.387 332.661 75.2925 333.587 76.4258 333.612H119.376C120.509 333.587 121.415 332.661 121.415 331.527C121.415 330.393 120.509 329.467 119.376 329.442H76.4258C75.2925 329.467 74.387 330.393 74.387 331.527Z"
      fill="#ECFFFD"
    />
  </svg>
)

export default ModalRight
