import { ROOM } from '../../constants'

const initState = {
  live_room: null,
  session_role: null,
  activeRoom: null,
  sequenceNumber: 0
}

const RoomReducer = (state = initState, action) => {
  const { type, payload } = action

  switch (type) {
    case ROOM.CREATE:
      return {
        ...state,
        live_room: payload
      }
    case ROOM.SELECT_ROLE:
      return {
        ...state,
        session_role: payload
      }
    case ROOM.SET_ROOM:
      return {
        ...state,
        activeRoom: payload
      }

    case ROOM.SET_SEQUENCE_NUMBER:
      return {
        ...state,
        sequenceNumber: payload
      }

    default:
      return state
  }
}

export default RoomReducer
