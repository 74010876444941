import React, { lazy, Suspense, Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'

import {
  DashboardOutlined,
  MailOutlined,
  UserOutlined,
  GroupOutlined,
  ClusterOutlined,
  SolutionOutlined,
  PicLeftOutlined,
  MessageOutlined
} from '@ant-design/icons'

import { Row, Col } from 'antd'

import PageLoad from './components/PageLoad'
import GuestGuard from './components/GuestGuard'
import AuthGuard from './components/AuthGuard'
import AdminGuard from './components/AdminGuard'
import RoomGuard from './components/RoomGuard'
import DemoRoomGuard from './components/DemoRoomGuard'

// Layout
import {
  RoomLayout,
  BasicLayout,
  AdminLayout,
  DashboardLayout,
  LandingLayout
} from './layouts'
import HomeGuard from './components/HomeGuard'

const NotFound = () => {
  return (
    <div className="container container-sm direction-column my-40 with-gutter prose">
      <Row>
        <Col span={24}>
          <h2 className="bigHeading">
            {"Sorry! That page doesn't seem to exist."}
          </h2>
        </Col>
      </Row>
    </div>
  )
}
export const routesConfig = [
  {
    path: '/onboarding',
    breadcrumbName: 'Onboarding',
    // guard: GuestGuard, should be logged in commenting to bypass for development
    component: lazy(() => import('./views/onboarding'))
  },
  {
    path: '/',
    // layout: LandingLayout,
    exact: true,
    guard: HomeGuard,
    breadcrumbName: 'Sign in',
    component: lazy(() => import('./views/auth/signin'))
  },
  {
    path: '/signin',
    breadcrumbName: 'Sign in',
    exact: true,
    guard: GuestGuard,
    component: lazy(() => import('./views/auth/signin'))
  },
  {
    path: '/signup',
    breadcrumbName: 'Sign up',
    exact: true,
    guard: GuestGuard,
    component: lazy(() => import('./views/auth/signup'))
  },
  {
    path: '/thanks-signup',
    breadcrumbName: 'Thank You For Signing up',
    layout: BasicLayout,
    exact: true,
    component: lazy(() => import('./views/auth/signup/thanks'))
  },
  {
    path: '/forgot-pwd',
    breadcrumbName: 'Forgot Password',
    exact: true,
    guard: GuestGuard,
    component: lazy(() => import('./views/auth/forgotPwd'))
  },
  {
    path: '/reset-pwd/:token',
    breadcrumbName: 'Reset Password Token Generated',
    exact: true,
    guard: GuestGuard,
    component: lazy(() => import('./views/auth/resetPwd'))
  },
  {
    path: '/chat/:roomname',
    breadcrumbName: 'Chat Room',
    layout: RoomLayout,
    guard: RoomGuard,
    exact: true,
    component: lazy(() => import('./views/room'))
  },
  {
    path: '/survey',
    breadcrumbName: 'Survey Form',
    layout: BasicLayout,
    guard: AuthGuard,
    exact: true,
    component: lazy(() => import('./views/survey'))
  },
  {
    path: '/demo',
    breadcrumbName: 'Demo',
    layout: RoomLayout,
    guard: DemoRoomGuard,
    exact: true,
    component: lazy(() => import('./views/room'))
  },
  {
    path: '/demo-2',
    breadcrumbName: 'Demo',
    layout: RoomLayout,
    guard: DemoRoomGuard,
    exact: true,
    component: lazy(() => import('./views/room'))
  },
  {
    path: '/home',
    layout: RoomLayout,
    guard: AuthGuard,
    breadcrumbName: 'Home',
    exact: true,
    component: lazy(() => import('./views/home'))
  },
  {
    path: '/get-started',
    layout: BasicLayout,
    exact: true,
    breadcrumbName: 'Get Started',
    component: lazy(() => import('./views/getstarted'))
  },
  {
    path: '/admin',
    layout: DashboardLayout,
    exact: true,
    guard: AdminGuard,
    breadcrumbName: 'Admin Dasboard',
    component: lazy(() => import('./views/admin')),
    navigation: {
      title: 'Dashboard',
      Icon: DashboardOutlined
    }
  },
  {
    path: '/admin/company',
    breadcrumbName: 'Admin Company View',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/company')),
    navigation: {
      title: 'Company',
      Icon: ClusterOutlined
    }
  },
  {
    path: '/admin/users',
    breadcrumbName: 'Admin Users View',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/users')),
    navigation: {
      title: 'Users',
      Icon: UserOutlined
    }
  },
  {
    path: '/admin/subscribe',
    breadcrumbName: 'Admin Subscribe View',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/subscribe')),
    navigation: {
      title: 'Subscribed Users',
      Icon: MailOutlined
    }
  },
  {
    path: '/admin/messages',
    breadcrumbName: 'Reported Messages',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/messages')),
    navigation: {
      title: 'Reported Messages',
      Icon: MessageOutlined
    }
  },
  {
    path: '/admin/interaction-type',
    breadcrumbName: 'Manage Interaction Type',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/interaction-type')),
    navigation: {
      title: 'Interaction Type',
      Icon: SolutionOutlined
    }
  },
  {
    path: '/admin/prompts',
    breadcrumbName: 'Manage Prompts',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/prompts')),
    navigation: {
      title: 'Prompts',
      Icon: PicLeftOutlined
    }
  },
  {
    path: '/admin/campaign-set-up',
    breadcrumbName: 'Manage Campaigns',
    layout: AdminLayout,
    exact: true,
    guard: AdminGuard,
    component: lazy(() => import('./views/admin/campaign')),
    navigation: {
      title: 'Campaign Set-Up',
      Icon: GroupOutlined
    }
  },
  {
    path: '*',
    breadcrumbName: '404 Not Found',
    layout: BasicLayout,
    component: NotFound
  }
]

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<PageLoad />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment
          const Layout = route.layout || Fragment
          const Component = route.component

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component
                        title={
                          route.breadcrumbName
                            ? `${route.breadcrumbName} : Tapestree`
                            : 'Tapestree'
                        }
                        {...props}
                      />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          )
        })}
      </Switch>
    </Suspense>
  ) : null

function Routes() {
  return renderRoutes(routesConfig)
}

export default Routes
