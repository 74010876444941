// Redux Type Constants
export const COMMON = {
  TOP_ALERT: 'TOP_ALERT',
  CHOOSE_BG: 'CHOOSE_BG',
  CLEAR_ALERT: 'CLEAR_ALERT'
}

export const AUTH = {
  SIGNED_IN: 'SIGNED_IN',
  SIGNED_OUT: 'SIGNED_OUT',
  USER_PROFILE: 'USER_PROFILE',
  USER_INFO: 'USER_INFO',
  UPDATE_USER: 'UPDATE_USER',
  BACKGROUND_IMAGE_UPLOAD: 'BACKGROUND_IMAGE_UPLOAD',
  CLIENT: 'CLIENT'
}

export const ROOM = {
  CREATE: 'CREATE',
  SELECT_ROLE: 'SELECT_ROLE',
  SET_ROOM: 'SET_ROOM',
  SET_SEQUENCE_NUMBER: 'SET_SEQUENCE_NUMBER'
}

export const COMMON_VALIDATE_MESSAGES = {
  required: 'Please enter ${label}',
  types: {
    email: 'Please enter a valid ${label}'
  },
  string: {
    min: '${label} must be at least 6 characters long'
  }
}
export const DEMO_ROOMS_PATH = ['/demo', '/demo-2']
export const DEMO_ROOMS = ['demo', 'demo-2']
export const RATINGKEY = 'rating' // use in feedback
export const INPUTKEY = 'ans' // use in feedback
export const THUMBTITLE = [
  'Never',
  'Seldom',
  'Sometimes',
  'Often',
  'Very Frequently (Top 5% of People)'
]

export const RICH_EDITOR_CONFIG = {
  statusbar: false,
  removeButtons: ['print', 'about'],
  toolbarSticky: false
}

export const CONV_STEPS = ['Minute Conversation', 'Minute Debrief', 'Rotate']

export const STEP_VAL = {
  conversation: 0,
  debrief: 1,
  rotate: 2,
  reset: 3
}

export const ROLES_VAL = {
  subject: 0,
  listener: 1,
  observer: 2
}

export const CONVERSATION_MIN = 7
export const DEBRIEF_MIN = 2
export const ROOM_NAME = `Focus on Active Listening`
export const ROOM_DESCRIPTION = `<p>Active listening is a core component of building trust and rapport with your team. It is the ability to focus completely on what the subject is saying and is not saying, to understand the meaning of what is said in the context of the subject's needs/challenges, and to support the subjects self-expression. Today’s focus will be on both active listening and contextualizing today's learnings.</p>`

export const ROLES = [
  {
    name: 'Subject',
    matadata: 'The person being coached around a relevant topic',
    about: `<p>Remark on one time over the past two weeks where communicating with a peer was not very effective. What was the intended goal of the conversation? How could you have approached the conversation differently for a different outcome?</p>`,
    tips: `<ul><li>As the subject, try your best to be present- centered on this conversation and in the moment. By being present you’re able to listen better, you pick up on, and sense things, better.</li><li>This time focusing on the prompt is meant to help you uncover insights and reflect on a particular moment in time.</li><li>If you find your mind wandering, re-acknowledge your commitment to being present, take a moment to reset, and renegade on the topic.</li><li>If you find that a question has not aligned, feel free to restate the question and try and build a bridge between the essence of the question and the focus of your time.</li></ul>`
  },
  {
    name: `Active Listener`,
    matadata: 'The person driving the conversation',
    about: `<p>Engage with the speaker and drive the conversation forward through the role as an active listener. Listen with full presence and focus. Listen with the whole body, be curious, observe, reflect back what he/she/they hear and guide the subject with open questions.</p>`,
    tips: `<ul><li>Good communication skills require a high level of self-awareness . Understanding your own personal style of communicating will go a long way toward helping you to create good and lasting impressions with others.</li><li>If you're finding it particularly difficult to concentrate on what someone is saying, try repeating their words mentally as they say them. This will reinforce their message and help you to stay focused.</li><li>Be aware that active listening can give others the impression that you agree with them even if you don't. It’s also important to avoid using active listening as a checklist of actions to follow, rather than really listening.</li><li>Use your own body language and gestures to show that you are engaged.</li><li>Our personal filters, assumptions, judgments, and beliefs can distort what we hear. As a listener, your role is to understand what is being said. This may require you to reflect on what is being said and to ask questions.<ul><li>For Example: Reflect on what has been said by paraphrasing. "What I'm hearing is... ," and "Sounds like you are saying... ," are great ways to reflect back.</li><li>Ask questions to clarify certain points. "What do you mean when you say... ." "Is this what you mean?"</li><li>Summarize the speaker's comments periodically.</li></ul></li></ul>`
  },
  {
    name: `Observer`,
    matadata: 'The person observing, and providing a third-party perspective',
    about: `<p>Observe the process and assess the active listener around the 3 core attributes below. Once the conversation has finished, share your feedback back to the active listener frame in a growth oriented way. You might reflect on what questions helped to spur on the conversation, what led to roadblocks, or what was really effective during the interaction.</p>`,
    tips: `<p>Key components of effective observations and feedback are consistent regardless of the learning environment (in-person, remote/virtual, or a hybrid setting). In general the best feedback is honest, specific, growth-oriented, actionable, and consistent. Some key components to consider when listening and presenting feedback include:</p><ul><li>How is the active listener communicating with their peer?</li><li>What specific language is being used, i.e., asset-based language which focuses on the individual strengths and offers support, or deficit-based language which focuses on the individual's weaknesses and draws attention to challenges?</li><li>Is the active listener engaged and able to support the conversation- providing a clear structure to when needed?</li><li>Is the listener quick to offer support?<ul><li>If so, when and under what circumstances?</li><li>If not, what was observed instead?</li></ul></li><li>Is the listener demonstrating flexibility?<ul><li>If so, how?</li><li>If not, what opportunities might exist to practice?</li></ul></li><li>How is their body language- are they engaged in the conversation?</li></ul>`
  }
]

export const FIRST_MODAL = {
  title: `Revisiting Learning Norms`,
  description: `<ul>
  <li>Take Space, Make Space</li>
  <li>Assume Best Intentions</li>
  <li>Speak from individual experiences</li>
  <li>Respect other individual's experience</li>
  <li>4 Walls Policy</li>
  <li>Any other team agreed upon norms</li>
  </ul>`
}

export const SECOND_MODAL = {
  title: `Getting Started`,
  description: `<ol>
  <li>Introduce Yourselves!
      <ul> <li> Take a few minutes to connect and catch up. </li> </ul>
  </li>
  <li>Review the summary and roles 
      <ul> <li> Quickly look through the roles to get an overview of the time </li> </ul>
   </li>
  <li>Choose a Role</li>
  <li>Role #2 is in charge of the timer</li>
  <li>The observer will drive the debrief. Use the feedback as a starting point!
  </li>
</ol>`
}

export const TOUR_STEPS = [
  {
    selector: '.first-step',
    content: 'Review the Content Refresher'
  },
  {
    selector: '.ant-tabs-nav-wrap',
    content: 'Look Through then Choose Your Role'
  },
  {
    selector: '.about',
    content: 'Review Role Descriptions'
  },
  {
    selector: '.feedback',
    content: `If you are the observer complete the form (during the conversation)`
  },
  {
    selector: '[data-tour="timer"]',
    content: `Start the timer and follow the instructions`
  }
]

export const PREFER_AUTH_METHOD_TYPES = ['Email and Password', 'Google OAuth']

export const PREFER_AUTH_METHOD = [
  {
    value: 0,
    label: PREFER_AUTH_METHOD_TYPES[0]
  },
  {
    value: 1,
    label: PREFER_AUTH_METHOD_TYPES[1]
  }
]
