import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'

function HomeGuard() {
  const auth = useSelector((state) => state.auth)

  if (auth.is_authed) {
    return <Redirect to="/home" />
  } else {
    return <Redirect to="/signin" />
  }
}

HomeGuard.propTypes = {
  children: PropTypes.any
}

export default HomeGuard
