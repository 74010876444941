import React, { memo, useEffect, useMemo, useState } from 'react'
import { Button, Layout, message, Tabs, Typography } from 'antd'
import Tour from 'reactour'
import TipCollapse from '../components/TipCollapse'
import styles from './layout.module.less'
import { Collapse } from 'antd'
import { DownCircleFilled, UpCircleOutlined } from '@ant-design/icons'
import {
  DEMO_ROOMS,
  INPUTKEY,
  RATINGKEY,
  ROLES_VAL,
  STEP_VAL
} from '../constants'
import AroundObjects from '../assets/images/Around-Objects.svg'
import LikedIcon from '../assets/images/survey/liked.png'
import GreenLeaf from '../assets/images/survey/green-leaf.png'
import { privateApi } from '../utils/api'

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
import { Doughnut } from 'react-chartjs-2'
import ConversationIcon from '../assets/icons/Conversation'

ChartJS.register(ArcElement, Tooltip, Legend)

const { Panel } = Collapse

const { Sider } = Layout
const { TabPane } = Tabs
const mainButtton = [
  'Start Timer',
  'Start Debrief',
  'Rotate Roles',
  'Click to Continue'
]
const nextObserverRole = ['Submit Feedback', 'Start Debrief']
// const Multiple = ['Multiple Speakers', 'Interruptions', 'Conversation Switches']

const SideBar = ({
  roles = [],
  currentRole,
  interaction,
  prompt,
  agreed,
  gotIt,
  _tourIsOpen,
  showHighfive,
  timerStep,
  roomName,
  timerCompleted,
  timerStartedOnce,
  timerStarted,
  sequenceNumber,
  ...props
}) => {
  const [isShowStartStopBtn, _isShowStartStopBtn] = useState(false)
  const [myTalkTime, _myTalkTime] = useState(undefined)
  const [totalSeconds, _totalSeconds] = useState(undefined)
  const [Conversation, _Conversation] = useState()
  const [strong, _strong] = useState([])
  const [growth, _growth] = useState([])
  const [tourIsOpen, setTourIsOpen] = useState(false)
  const [isShowStartStopBtnObs, setIsShowStartStopBtnObs] = useState(
    timerStep === STEP_VAL?.debrief && timerStartedOnce
  )
  const [error, _error] = useState(false)
  const [loading, _loading] = useState(false)
  const [formSubmitted, _formSubmitted] = useState(false)

  const goNextStep = () => {
    if (timerStep < STEP_VAL.reset) {
      if (!props.formSubmitted && !DEMO_ROOMS.includes(roomName)) {
        message.error('The observer has not submitted feedback form yet.')
      } else {
        !timerCompleted &&
          props.startOrPauseTimer(false, timerStep == STEP_VAL.conversation)
        props.setTimerStep(timerStep + 1)
        _isShowStartStopBtn(false)
        if (
          timerStep === STEP_VAL.debrief &&
          currentRole === roles?.[ROLES_VAL.observer]?.name
        ) {
          privateApi.post(`/room/${props?.roomId}/sequence/${sequenceNumber}`)
        }
      }
    } else if (!props.sequenceCompleted) {
      props.switchRole()
      _isShowStartStopBtn(false)
      const resetValue = props?.formFields?.map((e) => {
        return {
          ...e,
          [INPUTKEY]:
            DEMO_ROOMS.includes(roomName) && e.inputShow
              ? 'test answer for demo'
              : '',
          [RATINGKEY]: DEMO_ROOMS.includes(roomName) && e.ratingShow ? 3 : ''
        }
      })
      props?.setformFields(resetValue)
    }
  }

  const startOrPause = () => {
    if (timerStep < STEP_VAL.rotate) {
      props.startOrPauseTimer(!timerStarted)
    }
    _isShowStartStopBtn(true)
  }

  const _startTimer = () => {
    startOrPause()
    setIsShowStartStopBtnObs(true)
    // Update the room sequence if user has clicked on advance to rotate roles for the sequence of the the room
    privateApi.post(
      `/room/${props?.roomId}/sequence/${sequenceNumber}/${prompt?.id || 0}`
    )
  }

  const _saveFeedback = async () => {
    if (
      props.formFields?.some(
        (k) =>
          (k?.inputShow && k?.inputRequired && !k?.[INPUTKEY]) ||
          (k?.ratingShow && k?.ratingRequired && !k?.[RATINGKEY])
      )
    ) {
      message.error('Fill required fields.')
      _error(true)
      return
    }

    const formData = props.formFields?.map((d) => ({
      id: d._id || d.id,
      question: d?.name,
      [INPUTKEY]: d[INPUTKEY],
      [RATINGKEY]: d[RATINGKEY]
    }))
    _loading(true)

    try {
      await privateApi.post(`/feedback/${props?.roomId}`, {
        formData: JSON.parse(JSON.stringify(formData)),
        sequenceNumber: sequenceNumber
      })
      _loading(false)
      _formSubmitted(true)
      props?.submitFeedback?.() // socket call
      message.success('Thank you for the feedback.')
    } catch (err) {
      _loading(false)
      message.error(err.message)
    }
  }

  useEffect(() => {
    if (
      timerStep == STEP_VAL.reset &&
      (currentRole != roles?.[ROLES_VAL.observer]?.name ||
        DEMO_ROOMS.includes(roomName))
    ) {
      privateApi.get(`/talk-time/getTotal/${props?.sessionId}`).then((res) => {
        const twoRoles = res?.filter(
          (x) => x.role != roles?.[ROLES_VAL.observer]?.name
        )
        const total = twoRoles.reduce((n, m) => n + m.totalSeconds, 0)
        _totalSeconds(total)
        _myTalkTime(res?.find((x) => currentRole == x.role)?.totalSeconds || 0)
      })

      privateApi
        .get(`/talk-time/conversation-switched/${props?.sessionId}`)
        .then((res) => {
          _Conversation(res)
        })

      privateApi.get(`/feedback/session/${props?.sessionId}`).then((res) => {
        const feedBacks = res?.formData?.filter((a) => a.rating)
        const sortedArray = feedBacks?.sort((a, b) => b?.rating - a?.rating)
        const maxNumber = sortedArray?.[0]?.rating
        const minNumber = sortedArray?.[sortedArray?.length - 1]?.rating
        if (maxNumber == minNumber) {
          _strong(feedBacks)
        } else {
          _strong(feedBacks?.filter((x) => x?.rating == maxNumber))
          _growth(feedBacks?.filter((x) => x?.rating == minNumber))
        }
      })
    }
  }, [timerStep])

  useEffect(() => {
    if (props.showRoleTourTips) {
      setTourIsOpen(true)
    }
  }, [props.showRoleTourTips])

  const changeRole = (r) => {
    props.setCurrentRole(r)
  }

  const chartData = useMemo(() => {
    const per = Math.round((myTalkTime * 100) / totalSeconds) || 0
    const dataset = {
      datasets: [
        {
          data: [100 - per, per],
          backgroundColor: ['#EEF1F3', '#2D8EFF'],
          borderWidth: 0
        }
      ]
    }

    const plugin = [
      {
        beforeDraw: (chart) => {
          let width = chart.width,
            height = chart.height,
            ctx = chart.ctx
          let fontSize = (height / 125).toFixed(2)
          ctx.font = fontSize + 'em sans-serif'
          ctx.textBaseline = 'top'
          let text = `${per}%`, //totalTime
            textX = Math.round((width - ctx.measureText(text).width) / 2),
            textY = height / 2.4
          ctx.fillText(text, textX, textY)
          let fontSize1 = (height / 185).toFixed(2)
          ctx.font = fontSize1 + 'em sans-serif'
          ctx.textBaseline = 'top'
          let text1 = `Talk Time`,
            text1X = Math.round((width - ctx.measureText(text1).width) / 2),
            text1Y = height / 1.8
          ctx.fillText(text1, text1X, text1Y)
        }
      }
    ]

    return { dataset, plugin }
  }, [myTalkTime, totalSeconds])

  const tourSteps = useMemo(() => {
    return [
      {
        selector: '[data-tour="role-step"]',
        content: 'Follow the instructions listed here'
      },
      {
        selector: '[data-tour="tips-step"]',
        content:
          'Click through the elements of the conversation for additional support',
        resizeObservables: ['[data-tour="tips-step"]']
      },
      roles?.[ROLES_VAL.observer]?.name == currentRole
        ? {
            selector: '[data-tour="feedback-form-step"]',
            content:
              'Listen as a third party, take notes, and submit feedback before time is done!'
          }
        : roles?.[ROLES_VAL.listener]?.name === currentRole
        ? {
            selector: '[data-tour="start-timer-step"]',
            content:
              'Kick off the session by clicking this button and asking your peer a question related to your prompt'
          }
        : {
            selector: '[data-tour="video-box"]',
            content:
              'The coach will kick off with a question. Remember you will participate in each role!'
          }
    ]
  }, [roles, currentRole])

  const renderInstructionPoint = (instruction = '', textFade = false) => {
    return (
      <p
        className={`${styles.instructionText} ${
          textFade && styles.instructionTextFade
        }`}
      >
        {instruction}
      </p>
    )
  }

  const renderListenerButtons = () => {
    if (
      currentRole !== roles?.[ROLES_VAL.listener]?.name ||
      ![STEP_VAL.conversation, STEP_VAL.rotate].includes(timerStep)
    ) {
      return null
    }

    if (timerStep == STEP_VAL.conversation && timerCompleted) {
      return (
        <div className={styles.TimerButtons}>
          <button onClick={goNextStep} className={styles.RoleButton}>
            Advance to debrief
          </button>
        </div>
      )
    } else if (timerStep == STEP_VAL.conversation && isShowStartStopBtn) {
      return (
        <div className={styles.TimerButtons}>
          <button
            onClick={startOrPause}
            className={styles.RoleButton}
            data-tour="start-timer-step"
            autoFocus={timerStep == STEP_VAL.conversation && !_tourIsOpen}
          >
            {timerStarted ? 'Pause' : 'Start'} Timer
          </button>

          <Button onClick={goNextStep} className={styles.Debrief}>
            Advance to debrief early
          </Button>

          {/* <div style={{ marginTop: 10 }}>
            {renderInstructionPoint('Click on advance to debrief', false, true)}
          </div> */}
        </div>
      )
    } else {
      return (
        <>
          <button
            className={styles.RoleButton}
            key={!!currentRole && gotIt && agreed && !_tourIsOpen}
            autoFocus={!!currentRole && gotIt && agreed && !_tourIsOpen}
            onClick={() =>
              timerStep < STEP_VAL.rotate
                ? startOrPause()
                : props.setTimerStep(STEP_VAL.reset)
            }
            data-tour="start-timer-step"
          >
            <span>{mainButtton[timerStep]}</span>
          </button>
        </>
      )
    }
  }

  const renderObserverButton = () => {
    if (currentRole !== roles?.[ROLES_VAL.observer]?.name) {
      return null
    }
    if (timerStep === STEP_VAL.debrief && timerCompleted) {
      return (
        <div className={styles.TimerButtons}>
          <button autoFocus onClick={goNextStep} className={styles.RoleButton}>
            <span>Advance to rotate</span>
          </button>
        </div>
      )
    } else if (timerStep === STEP_VAL.debrief && isShowStartStopBtnObs) {
      return (
        <div className={styles.TimerButtons}>
          <button
            onClick={startOrPause}
            className={styles.RoleButton}
            autoFocus
          >
            <span>{timerStarted ? 'Pause' : 'Start'} Timer</span>
          </button>

          <Button onClick={goNextStep} className={styles.Debrief}>
            Advance to rotate early
          </Button>
        </div>
      )
    } else if (
      timerStep === STEP_VAL.debrief ||
      (timerStep === STEP_VAL.conversation && timerStartedOnce)
    ) {
      return (
        <Button
          shape="round"
          loading={loading}
          autoFocus={timerStep > STEP_VAL.conversation}
          onClick={() =>
            timerStep == STEP_VAL.conversation ? _saveFeedback() : _startTimer()
          }
          className={`${
            formSubmitted && timerStep == STEP_VAL.conversation
              ? styles.disabledButton
              : ''
          } ${styles.RoleButton}`}
          disabled={formSubmitted && timerStep == STEP_VAL.conversation}
          data-tour="debrief-start-step"
        >
          <span>
            {formSubmitted && timerStep == STEP_VAL.conversation
              ? 'Waiting for coach to advance timer'
              : nextObserverRole[timerStep]}
          </span>
        </Button>
      )
    }
  }

  const instructionsByRole = (currentRole) => {
    switch (currentRole) {
      case roles?.[ROLES_VAL.listener]?.name: {
        return [
          {
            message: 'Read Role Then Start Timer',
            isActive: timerStep >= STEP_VAL.conversation
          },
          {
            message: 'Engage with Participant',
            isActive:
              (timerStep >= STEP_VAL.conversation && timerStartedOnce) ||
              timerStep >= STEP_VAL.debrief
          },
          {
            message: 'Engage in debrief',
            isActive:
              (timerStep >= STEP_VAL.debrief && timerStartedOnce) ||
              timerStep > STEP_VAL.debrief
          }
        ]
      }

      case roles?.[ROLES_VAL.subject]?.name: {
        return [
          {
            message: 'Review Role ',
            isActive: timerStep >= STEP_VAL.conversation
          },
          {
            message: 'Engage with Coach',
            isActive:
              (timerStep === STEP_VAL.conversation && timerStartedOnce) ||
              timerStep >= STEP_VAL.debrief
          },
          {
            message: 'Engage in debrief discussion',
            isActive:
              (timerStep >= STEP_VAL.debrief && timerStartedOnce) ||
              timerStep > STEP_VAL.debrief
          }
        ]
      }

      case roles?.[ROLES_VAL.observer]?.name: {
        return [
          {
            message: 'Review Role ',
            isActive: timerStep >= STEP_VAL.conversation
          },
          {
            message: 'Fill and submit reflections',
            isActive:
              (timerStep >= STEP_VAL.conversation && timerStartedOnce) ||
              timerStep > STEP_VAL.conversation
          },
          {
            message: 'Start Debrief Timer',
            isActive: timerStep >= STEP_VAL.debrief
          },
          {
            message: 'Lead Debrief',
            isActive:
              (timerStep >= STEP_VAL.debrief && timerStartedOnce) ||
              timerStep > STEP_VAL.debrief
          }
        ]
      }

      default: {
        return [
          {
            message: 'Read Role Then Start Timer',
            isActive: timerStep >= STEP_VAL.conversation
          }
        ]
      }
    }
  }

  const renderActiveInstructionsByRole = (currentRole) => {
    if (timerStep >= STEP_VAL.rotate) return null
    const instructions = instructionsByRole(currentRole)
    const getLastActiveInstruction = instructions
      .map((i) => i.isActive)
      .lastIndexOf(true)
    const remainingSteps = instructions.slice(getLastActiveInstruction + 1)

    return (
      <div data-tour="role-step" className={styles.instructionSet}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%'
          }}
        >
          {renderInstructionPoint(
            instructions[getLastActiveInstruction]?.message || 'Review Role'
          )}
          <div className={styles.pointContainer}>
            {instructions.map((step) => (
              <div
                className={`${styles.point} ${
                  step.isActive ? styles.activePoint : ''
                }`}
              />
            ))}
          </div>
        </div>
        {!!remainingSteps && remainingSteps?.length > 0 && (
          <Collapse ghost size="small">
            <Panel
              showArrow={false}
              key="remaining-steps"
              className={styles.followingStepsContainer}
              header={
                <div className={styles.followingSteps}>
                  <Typography.Text style={{ fontSize: '14px' }}>
                    Following steps
                  </Typography.Text>
                  <DownCircleFilled
                    style={{
                      marginLeft: '6px',
                      marginRight: '0'
                    }}
                  />
                </div>
              }
            >
              <div className={styles.remainingInstructions}>
                {remainingSteps.map((step) =>
                  renderInstructionPoint(step.message, true)
                )}
              </div>
            </Panel>
          </Collapse>
        )}
      </div>
    )
  }

  const tipCollapse = useMemo(() => {
    return (
      <TipCollapse
        role={roles?.find((k) => k?.name == currentRole)}
        roles={roles}
        currentRole={currentRole}
        showbtn={
          roles?.[ROLES_VAL.listener]?.name == currentRole ||
          timerStep == STEP_VAL.reset
        }
        interaction={interaction}
        questions={props?.formFields}
        isExpanded={roles?.[ROLES_VAL.observer]?.name !== currentRole}
        roleDis={prompt?.rolesDescription}
        prompt={prompt?.prompt}
        promptData={prompt}
        timerStep={timerStep}
        roomId={props?.roomId}
        isRoleSelected={currentRole}
        submitFeedback={props?.submitFeedback}
        startOrPause={startOrPause}
        goNextStep={goNextStep}
        timerStarted={timerStarted}
        timerStartedOnce={timerStartedOnce}
        timerCompleted={timerCompleted}
        sequenceNumber={sequenceNumber}
        setformFields={props?.setformFields}
        formSubmitted={formSubmitted}
      />
    )
  }, [
    currentRole,
    timerStep,
    props?.formFields,
    timerStarted,
    props?.roomId,
    props?.submitFeedback,
    timerStartedOnce,
    props?.timerCompleted,
    sequenceNumber,
    props?.setformFields
  ])

  return (
    <>
      <Tour
        isOpen={tourIsOpen}
        onRequestClose={() => setTourIsOpen(false)}
        showNumber={false}
        showNavigation={false}
        closeWithMask={false}
        rounded={8}
        accentColor={'#0B9ED0'}
        steps={tourSteps}
        lastStepNextButton={
          <div
            className="ant-btn ant-btn-primary"
            onClick={() => setTourIsOpen(false)}
          >
            Done
          </div>
        }
      />
      <Sider
        collapsible
        collapsed={props.collapsed}
        onCollapse={props.onCollapse}
        defaultCollapsed={false}
        collapsedWidth={0}
        width={350}
        theme="light"
        className={`${styles.RightSidebar} ${
          showHighfive || props.sequenceCompleted ? 'sidebar-toggle-btn' : ''
        }`}
        style={{ padding: 0 }}
      >
        <div className={styles.RightSidebarContainer}>
          {!currentRole && (
            <div className={styles.ChooseRolesTitle}>
              <h3>Choose Roles</h3>
            </div>
          )}
          {DEMO_ROOMS.includes(roomName) ? (
            <Tabs
              type="card"
              className={`role-tab ${styles.tabContainer}`}
              defaultActiveKey={currentRole}
              moreIcon={null}
              onChange={changeRole}
            >
              {roles?.map((ro) => (
                <TabPane tab={ro?.name} key={ro?.name}>
                  {renderActiveInstructionsByRole(ro?.name)}
                </TabPane>
              ))}
            </Tabs>
          ) : (
            <>
              <div className={styles.RoleHeader}>{currentRole}</div>
              <div className={styles.instructionContainer}></div>
              {renderActiveInstructionsByRole(currentRole)}
            </>
          )}

          {timerStep == STEP_VAL.reset ? (
            <>
              {currentRole == roles?.[ROLES_VAL.observer]?.name ? (
                <div className={styles.observerThanks}>
                  <h3>Thank you for supporting your peer!</h3>
                  <img src={AroundObjects} alt="alt" />
                </div>
              ) : currentRole == roles?.[ROLES_VAL.listener]?.name ||
                currentRole == roles?.[ROLES_VAL.subject]?.name ? (
                <div className={styles.ChartView}>
                  {totalSeconds ? (
                    <div style={{ textAlign: 'center', fontSize: 14 }}>
                      {Math.floor(totalSeconds / 60)}:
                      {`${Math.floor(totalSeconds % 60)}`.padStart(2, 0)}{' '}
                      Conversation
                    </div>
                  ) : null}
                  <div style={{ width: 140, height: 140, margin: '0px auto' }}>
                    {totalSeconds != undefined && myTalkTime != undefined ? (
                      <Doughnut
                        data={chartData?.dataset}
                        plugins={chartData?.plugin}
                        options={{
                          cutout: 50
                        }}
                      />
                    ) : null}
                  </div>

                  {currentRole != roles?.[ROLES_VAL.observer]?.name && (
                    <div className={styles.ListenerTab}>
                      <div className={styles.MultipleTab}>
                        {/* <div className={styles.Round}></div> */}
                        <div>
                          <ConversationIcon />
                        </div>
                        <div style={{ marginLeft: 10, width: '100%' }}>
                          <div className={styles.MultipleTitle}>
                            Conversation Switches
                          </div>
                          <div style={{ fontSize: 9 }}>
                            Number of times the primary speaker switches
                          </div>
                        </div>
                      </div>
                      <div className={styles.Average}>
                        <span>{Conversation?.totalConversation}</span>
                        {/* <div>avg. 7</div> */}
                      </div>
                    </div>
                  )}

                  {currentRole == roles?.[ROLES_VAL.listener]?.name ? (
                    <>
                      {strong?.length ? (
                        <div className={styles.Growth}>
                          <div className={styles.GrowthTitle}>
                            Strongest Skill
                          </div>
                          {strong?.map((k, i) => (
                            <div key={i} className={styles.Mirroring}>
                              <img src={LikedIcon}></img>
                              <span>{k?.question}</span>
                            </div>
                          ))}
                        </div>
                      ) : null}
                      {growth?.length ? (
                        <div className={styles.Growth}>
                          <div className={styles.GrowthTitle}>Growth Area</div>
                          {growth?.map((k, i) => (
                            <div key={i} className={styles.Mirroring}>
                              <img src={GreenLeaf}></img>
                              <span>{k?.question}</span>
                            </div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ) : null}
            </>
          ) : null}

          {renderListenerButtons()}
          {renderObserverButton()}

          {timerStep == STEP_VAL.reset && !props?.sequenceCompleted ? (
            <button
              autoFocus
              onClick={goNextStep}
              className={styles.RoleButton}
            >
              <span>{mainButtton[timerStep]}</span>
            </button>
          ) : null}
          {timerStep != STEP_VAL.reset && tipCollapse}
        </div>
      </Sider>
    </>
  )
}

export default memo(SideBar)
