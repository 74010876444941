import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { message } from 'antd'

function DemoRoomGuard({ children }) {
  const auth = useSelector((state) => state.auth)
  if (!auth.is_authed) {
    return <Redirect to="/signin" />
  }

  if (!auth.is_verified && !auth.is_admin) {
    message.warning('Please contact the administrator for access!')
    return <Redirect to="/" />
  }

  return children
}

DemoRoomGuard.propTypes = {
  children: PropTypes.any
}

export default DemoRoomGuard
