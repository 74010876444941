import React, { useState } from 'react'
import { Layout } from 'antd'
import SideBar from './sidebar'
import Header from './header'
import Footer from './footer'
import styles from './layout.module.less'
import AroundObjects from '../assets/images/Around-Objects.svg'
import ModalRight from '../assets/icons/ModalRight'
import AdminSidebar from './adminSidebar'

const RoomLayout = (props) => {
  return (
    <Layout className={`${styles.RoomLayout} room-layout`}>
      <Header style={{ backgroundColor: '#fff' }} />
      <Layout className={styles.ReverseLayout}>{props.children}</Layout>
    </Layout>
  )
}

const AdminLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#fff' }} />
      <Layout>
        <AdminSidebar />
        <Layout.Content className={styles.AdminContainer}>
          {props.children}
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

const BasicLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header className={`${styles.Header} ${styles.HeaderWithBg}`} />
      <Layout.Content style={{ backgroundColor: '#fff' }}>
        {props.children}
      </Layout.Content>
      <Footer />
    </Layout>
  )
}

const ModalLayout = (props) => {
  return (
    <div className={styles.ModalLayout}>
      <div className={styles.Content}>{props.children}</div>
      <div className={styles.ModalRight}>
        <img src={AroundObjects} alt="alt" />
      </div>
    </div>
  )
}

const DashboardLayout = (props) => {
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Header style={{ backgroundColor: '#fff' }} />
      <Layout>
        <AdminSidebar />
        <Layout.Content>
          <div>{props.children}</div>
        </Layout.Content>
      </Layout>
    </Layout>
  )
}

const LandingLayout = (props) => {
  return (
    <Layout style={{ backgroundColor: '#fff' }}>
      {props.children}
      <Footer />
    </Layout>
  )
}

const ModalWithoutImageLayout = (props) => {
  return (
    <div className={styles.ModalLayout}>
      <div className={styles.Content}>{props.children}</div>
    </div>
  )
}
export {
  RoomLayout,
  BasicLayout,
  ModalLayout,
  AdminLayout,
  DashboardLayout,
  LandingLayout,
  ModalWithoutImageLayout
}
