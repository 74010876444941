import React, { memo } from 'react'
import { Row, Col, Space } from 'antd'
import styles from './layout.module.less'

const currentYear = new Date().getFullYear()

const Footer = () => (
  <div className={styles.Footer}>
    <div className={styles.policy} style={{ padding: '40px 0' }}>
      <div className="container direction-column with-gutter">
        <Row gutter={30}>
          <Col xs={24} md={12}>
            <span style={{ color: '#fff', marginBottom: 0 }}>
              © {currentYear}
            </span>
          </Col>
          <Col xs={24} md={12}>
            <Space style={{ width: '100%', flexDirection: 'row-reverse' }}>
              <a href={`${process.env.MARKETING_SITE_BASE_URL}/privacy-policy`}>
                Privacy Policy
              </a>
              <a
                href={`${process.env.MARKETING_SITE_BASE_URL}/terms-and-conditions`}
              >
                Terms & Conditions
              </a>
            </Space>
          </Col>
        </Row>
      </div>
    </div>
  </div>
)

export default memo(Footer)
