import React, { useMemo, memo } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { MenuOutlined } from '@ant-design/icons'
import { Layout, Dropdown, Menu, Button } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import AuthActions from '@/services/auth'
import { NavHashLink } from 'react-router-hash-link'

import LogoIcon from '@/assets/images/logo.png'
import LogoWhite from '@/assets/images/logo-white.png'
import styles from './layout.module.less'
import { DEMO_ROOMS } from '../constants'

const Header = (props) => {
  const { location, push } = useHistory()

  const { userInfo, is_admin, availableRoom, client } = useSelector(
    (state) => state.auth
  )

  const dispatch = useDispatch()

  const isRoomRoute = useMemo(
    () =>
      location?.pathname?.includes?.('/demo') ||
      location?.pathname?.includes?.('/demo-2') ||
      location?.pathname?.includes?.('/chat'),
    [location]
  )

  const logout = () => {
    dispatch(AuthActions.logOut())
  }

  const home = () => {
    if (isRoomRoute) return

    push('/')
    window.location.replace('/') // reload page for remove video
  }

  const handalenavigation = async () => {
    push('/admin')
    if (client) {
      await client?.leave()
    }
  }

  return (
    <Layout.Header className={styles.Header} {...props}>
      {(userInfo && isRoomRoute) || location?.pathname?.includes?.('/admin') ? (
        <>
          <img
            src={LogoIcon}
            width="151"
            height="39"
            alt="logo"
            onClick={home}
            style={{ cursor: isRoomRoute ? 'default' : 'pointer' }}
          />
        </>
      ) : (
        <img
          src={LogoWhite}
          width="151"
          height="39"
          alt="logo"
          onClick={home}
          style={{ cursor: isRoomRoute ? 'default' : 'pointer' }}
        />
      )}
      <div className={styles.Right}>
        {userInfo ? (
          <>
            {!isRoomRoute && (
              <Button
                tabIndex={1}
                autoFocus
                type="default"
                style={{ marginRight: '20px' }}
              >
                <NavLink
                  to={
                    availableRoom && availableRoom.name
                      ? DEMO_ROOMS.includes(availableRoom.name)
                        ? `/${availableRoom.name}`
                        : `/chat/${availableRoom.name}`
                      : null
                  }
                >
                  Join Room
                </NavLink>
              </Button>
            )}
            <Dropdown
              tabIndex={1}
              trigger={['hover', 'click']}
              overlay={
                <Menu>
                  {is_admin && (
                    <>
                      <Menu.Item key="admin-panel" autoFocus>
                        <a onClick={handalenavigation}>Admin Panel</a>
                      </Menu.Item>
                      <Menu.Divider />
                    </>
                  )}
                  <Menu.Item key="logout">
                    <a onClick={logout}>Log Out</a>
                  </Menu.Item>
                </Menu>
              }
              placement="bottomRight"
            >
              {userInfo.avatar ? (
                <img src={userInfo.avatar} />
              ) : (
                <MenuOutlined style={{ width: 30 }} />
              )}
            </Dropdown>
          </>
        ) : (
          <>
            <div className={styles.Navbar}>
              <Button type="text" className={styles.BlogLink}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.MARKETING_SITE_BASE_URL}/aboutus`}
                >
                  About us
                </a>
              </Button>
              <Button type="text" className={styles.BlogLink}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.MARKETING_SITE_BASE_URL}/ergs`}
                >
                  For ERGs
                </a>
              </Button>
              <Button type="text" className={styles.BlogLink}>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={`${process.env.MARKETING_SITE_BASE_URL}/contact-us`}
                >
                  Contact
                </a>
              </Button>
              <Button type="ghost" href="/signin" className={styles.GhostBtn}>
                Log in
              </Button>
              <Button
                type="default"
                href="/signup"
                className={styles.SignupBtn}
              >
                Sign up
              </Button>
            </div>
            <div className={styles.Menubar}>
              <div className={styles.dropdown}>
                <button className={styles.dropbtn}>
                  <MenuOutlined />
                </button>
                <div className={styles.dropdownContent}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.MARKETING_SITE_BASE_URL}/aboutus`}
                  >
                    About
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.MARKETING_SITE_BASE_URL}/ergs`}
                  >
                    For ERGs
                  </a>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`${process.env.MARKETING_SITE_BASE_URL}/contact-us`}
                  >
                    Contact
                  </a>
                  <NavLink to="/signin">Log In</NavLink>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </Layout.Header>
  )
}

export default memo(Header)
