import React, { useMemo, Fragment } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Layout, Menu } from 'antd'

import { routesConfig } from '@/Routes'
import { useSelector } from 'react-redux'

const { SubMenu } = Menu
const { Sider } = Layout

const AdminSidebar = () => {
  const history = useHistory()
  const location = useLocation()
  const { userInfo } = useSelector((state) => state.auth)

  const routers = useMemo(() => {
    if (userInfo.type == 'company_admin') {
      return routesConfig?.filter(
        (x) =>
          x.path !== '/admin/company' &&
          x.path !== '/admin/subscribe' &&
          x.path !== '/admin/interaction-type' &&
          x.path !== '/admin/prompts'
      )
    } else {
      return routesConfig
    }
  }, [])

  const renderNavigation = (
    { navigation = null, children = null, path = '/' },
    key
  ) => {
    if (!navigation) return null

    const Icon = navigation.Icon || Fragment
    const title = navigation.title || ''

    return (
      <Fragment key={key}>
        {children ? (
          <SubMenu
            key={key}
            icon={<Icon />}
            title={title}
            className="menu__item"
          >
            {children.map(renderNavigation)}
          </SubMenu>
        ) : (
          <Menu.Item key={path} icon={<Icon />} className="menu__item">
            {title}
          </Menu.Item>
        )}
      </Fragment>
    )
  }

  const getSelectedMenu = () => {
    const route = routers.filter((ro) => ro.path == location.pathname)[0]
    const activeMenu = route ? route.parent || route.path : ''
    return [activeMenu]
  }

  return (
    <Sider
      width={220}
      theme="light"
      className="sidebar"
      style={{ paddingTop: 30 }}
    >
      <Menu
        onClick={({ key }) => history.push(key)}
        selectedKeys={getSelectedMenu()}
        className="sidebar__menu"
        mode="inline"
      >
        {routers.map(renderNavigation)}
      </Menu>
    </Sider>
  )
}

export default AdminSidebar
