import * as React from 'react'

const ConversationIcon = (props) => (
  <svg
    xmlSpace="preserve"
    width={40}
    height="auto"
    viewBox="0 0 3733.333 2666.667"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M28000 0H0v20000h28000V0"
      style={{
        fill: 'transparent'
      }}
      transform="matrix(.13333 0 0 -.13333 0 2666.667)"
    />
    <path
      d="m22137.8 7499.3-3834.3 3834.2V9322.2h-6292.8V5676.3h6292.8V3665.1l3834.3 3834.2M5862.25 12500.7l3834.23 3834.2v-2011.2h6292.82v-3646H9696.48V8666.5l-3834.23 3834.2"
      //   style={{
      //     fill: '#211d1d',
      //     fillOpacity: 1,
      //     fillRule: 'nonzero',
      //     stroke: 'none'
      //   }}
      transform="matrix(.13333 0 0 -.13333 0 2666.667)"
    />
  </svg>
)

export default ConversationIcon
