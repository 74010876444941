import React from 'react'
import { hot } from 'react-hot-loader/root'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'

// All Routes
import Routes from './Routes'

import AuthCheck from './components/AuthCheck'
import Alert from './components/Alert'
import { initializeApp } from 'firebase/app'
import { firebaseConfig, getAnalytics } from './utils/initFirebase'

const history = createBrowserHistory()

initializeApp(firebaseConfig)
class App extends React.Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    getAnalytics()
  }

  render() {
    return (
      <div className="App">
        <AuthCheck>
          <Alert />
          <Router history={history}>
            <Routes />
          </Router>
        </AuthCheck>
      </div>
    )
  }
}

export default hot(App)
